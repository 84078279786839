import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { Card, IconButton, Paragraph, Title } from 'components/common';
import { CrookedArrowIcon } from 'components/common/icons';

import { useUserProfileQuery } from 'modules/auth/authApiSlice';
import { getUserIdentifier } from 'modules/auth/utils';

import { nanoTwMerge } from 'services/twMerge';

import { cardDetails } from './utils';

const classes = {
  card: classNames([
    'relative',
    'rounded-3xl',
    'bg-card-dark-gradient',
    'p-8',
    'pt-16',
    'h-[280px]',
    'bg-transparent',
    'bg-no-repeat',
    'shadow-lg',
    'hover:transform',
    'hover:scale-105',
    'transition-transform',
    'duration-300',
  ]),
  iconButton: classNames([
    'absolute',
    'bottom-[32px]',
    'right-[32px]',
    'rounded-full',
    'hover:text-secondary-white',
    'transition-all',
    'shadow-md',
    'hover:bg-opacity-80',
  ]),
};

export const AdminPanelPage = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { data: user } = useUserProfileQuery(null);
  const userIdentifier = user && getUserIdentifier(user);

  return (
    <>
      <header className="mb-8">
        <Title className="text-primary-dark-purple-100 text-3xl font-bold">
          {t('adminPanel.title')}
          <span className="block text-title font-bold text-primary-light-purple-100 text-4xl">
            {userIdentifier}!
          </span>
        </Title>
      </header>

      <div className="grid gap-8">
        <div className="grid grid-cols-2 gap-8">
          {cardDetails.slice(0, 2).map((card, index) => (
            <Card key={index} className={nanoTwMerge(classes.card, 'home-card', card.bgClass)}>
              <Title className="mb-2 text-secondary-white" level={2}>
                {t(card.titleKey)}
              </Title>
              <Paragraph className="max-w-[140px] text-secondary-white mt-10">
                {t(card.descriptionKey)}
              </Paragraph>
              <IconButton
                className={nanoTwMerge(classes.iconButton, 'home-card-button', card.btnClass)}
                size="large"
                onClick={() => navigate(card.route)}
              >
                <CrookedArrowIcon />
              </IconButton>
            </Card>
          ))}
        </div>
        <div className="grid grid-cols-3 gap-8">
          {cardDetails.slice(2).map((card, index) => (
            <Card key={index} className={nanoTwMerge(classes.card, 'home-card', card.bgClass)}>
              <Title className="mb-2 text-secondary-white" level={2}>
                {t(card.titleKey)}
              </Title>
              <Paragraph className="max-w-[135px] text-secondary-white mt-10">
                {t(card.descriptionKey)}
              </Paragraph>
              <IconButton
                className={nanoTwMerge(classes.iconButton, 'home-card-button', card.btnClass)}
                size="large"
                onClick={() => navigate(card.route)}
              >
                <CrookedArrowIcon />
              </IconButton>
            </Card>
          ))}
        </div>
      </div>
    </>
  );
};
