import { Switch as SwitchAntd, SwitchProps as SwitchAntdProps } from 'antd';
import classNames from 'classnames';
import { memo, ReactNode } from 'react';

interface SwitchProps extends SwitchAntdProps {
  children?: ReactNode;
}

export const Switch = memo(
  ({ className, children, disabled, ...rest }: Omit<SwitchProps, 'size'>) => (
    <label
      className={classNames('inline-flex align-middle hover:cursor-pointer hover:text-text-100', {
        'text-text-40 hover:cursor-not-allowed hover:text-text-40': disabled,
      })}
    >
      <SwitchAntd className={classNames(className, 'nano-switch')} disabled={disabled} {...rest} />
      <span
        className={classNames('ml-3 text-base-l', {
          'hover:cursor-not-allowed': disabled,
        })}
      >
        {children}
      </span>
    </label>
  ),
);
Switch.displayName = 'Switch';
