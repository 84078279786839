import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { notificationService } from '../../notifications';
import { updateTeamFailure } from '../team';
import { domainListApi } from './domainListApi';
import { DomainListRequestValues, DomainListResponse } from './domainListApiTypes';
import { getDomainList, getDomainListToDeleteId } from './domainListSelectors';
import {
  deleteDomainListFailure,
  deleteDomainListItem,
  deleteDomainListSuccess,
  getDomainListById,
  getDomainListByIdSuccess,
  saveDomainList,
  saveDomainListFailure,
  saveDomainListSuccess,
  updateDomainList,
  updateDomainListSuccess,
} from './domainListSlice';
import { domainListApiSlice } from './list/domainListApiSlice';
import { DomainListState } from './types';

function* saveDomainListSaga(action: PayloadAction<void, string, { refetch?: () => void }>) {
  try {
    const domainListState: DomainListState = yield select(getDomainList);
    const domainListData: DomainListRequestValues = {
      name: domainListState.name,
      list: domainListState.list,
    };

    const response: DomainListResponse = yield call(domainListApi.saveDomainList, domainListData);

    yield put(saveDomainListSuccess());
    if (response.name !== domainListState.name) {
      notificationService.showInfo({
        key: 'sameDomainListSuccess',
        message: `${i18next.t('adminPanel.domainList.sameDomainList')} ${response.name}`,
      });
    }
    yield put(domainListApiSlice.util.invalidateTags(['DomainList']));
    if (action.meta?.refetch) {
      action.meta.refetch();
    }
  } catch (e) {
    notifyError(e);
    yield put(
      saveDomainListFailure({
        message: i18next.t('adminPanel.domainList.errors.domainListSaveFailed'),
      }),
    );
  }
}

function* deleteDomainListSaga() {
  try {
    const itemToDelete: string = yield select(getDomainListToDeleteId);

    yield call(domainListApi.deleteDomainListItem, itemToDelete);

    yield put(deleteDomainListSuccess());
    yield put(domainListApiSlice.util.invalidateTags(['DomainList']));
  } catch (e) {
    notifyError(e);
    yield put(
      deleteDomainListFailure({
        message: i18next.t('adminPanel.domainList.errors.domainListItemDeleteFailed'),
      }),
    );
  }
}

function* updateDomainListSaga() {
  try {
    const domainListState: DomainListState = yield select(getDomainList);
    const domainListData: DomainListRequestValues = {
      name: domainListState.name,
      list: domainListState.list,
    };

    yield call(domainListApi.updateDomainList, domainListState.id, domainListData);

    yield put(domainListApiSlice.util.invalidateTags(['DomainList']));
    yield put(updateDomainListSuccess());
  } catch (e) {
    notifyError(e);

    yield put(
      updateTeamFailure({
        message: i18next.t('adminPanel.team.errors.domainListUpdateFailed'),
      }),
    );
  }
}

function* getDomainListByIdSaga() {
  try {
    const domainListState: DomainListState = yield select(getDomainList);

    const domainListResponse: DomainListResponse = yield call(
      domainListApi.getDomainListById,
      domainListState.id,
    );

    yield put(domainListApiSlice.util.invalidateTags(['DomainList']));
    yield put(getDomainListByIdSuccess(domainListResponse));
  } catch (e) {
    notifyError(e);

    yield put(
      updateTeamFailure({
        message: i18next.t('adminPanel.team.errors.domainListFetchFailed'),
      }),
    );
  }
}

export function* domainListSagas() {
  yield all([takeLatest(saveDomainList.type, saveDomainListSaga)]);
  yield all([takeLatest(deleteDomainListItem.type, deleteDomainListSaga)]);
  yield all([takeLatest(updateDomainList.type, updateDomainListSaga)]);
  yield all([takeLatest(getDomainListById.type, getDomainListByIdSaga)]);
}
