import { api } from 'services/api';

import { SEGMENT_URL, SEGMENTS_URL, BRIEF_UPLOAD_URL } from './constants';
import { SegmentDetailsResponse } from './segmentApiTypes';

const getSegments = () => {
  return api.get(SEGMENTS_URL);
};

const getSegmentById = (id: string) => {
  return api.get<SegmentDetailsResponse>(`${SEGMENT_URL}/${id}`);
};

const saveSegment = (data: unknown) => api.post('/segment/', data);

const updateSegment = (id: string, data: unknown) => {
  return api.put(`${SEGMENT_URL}/${id}`, data);
};

const deleteSegment = (id: string) => {
  return api.delete(`${SEGMENT_URL}/${id}`);
};

const getBriefUploadData = (data: FormData) => {
  return api.post(BRIEF_UPLOAD_URL, data);
};

export const segmentsApi = {
  getSegments,
  getSegmentById,
  saveSegment,
  updateSegment,
  deleteSegment,
  getBriefUploadData,
};
