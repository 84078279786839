import { Divider } from 'antd';
import classNames from 'classnames';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Button, IconButton, Paragraph, Popover, Title, Tooltip } from 'components/common';
import { UniversalBackground } from 'components/common/UniversalBackground';
import { MessageSquare, UserIcon, UserProfileIcon } from 'components/common/icons';
import { MagicBulb } from 'components/common/icons/MagicBulb';

import { RolesPermission } from 'modules/adminPanel/users/types';
import { useCurrentRoleQuery, useUserProfileQuery } from 'modules/auth/authApiSlice';
import { useAuth } from 'modules/auth/useAuth';
import { getUserIdentifier } from 'modules/auth/utils';
import { useNanoBotActions } from 'modules/nanoBot/useNanoBotActions';
import { NANO_TOUR_IDS, useNanoTourContext } from 'modules/tour';

import { nanoTwMerge } from 'services/twMerge';

import { usePendo } from 'hooks/usePendo';

import { AdminPanelButton } from '../AdminPanel/AdminPanelButton';

const classes = {
  iconContainer: classNames([
    'flex',
    'items-center',
    'justify-center',
    'w-[84px] h-[84px]',
    'top-[-42px] left-0 right-0',
    'm-auto',
    'rounded-full',
    'absolute',
    'bg-secondary-white',
  ]),
  iconContainerAfter: classNames([
    // eslint-disable-next-line quotes
    "after:content-['']",
    'after:block',
    'after:absolute',
    'after:left-[6px] after:top-[6px] after:bottom-[6px] after:right-[6px]',
    'after:bg-profile-gradient',
    'after:rounded-full',
  ]),
  icon: classNames(['text-primary-dark-purple-100', 'z-10']),
};

const SIDEBAR_BTN_CLASSES = classNames([
  'text-primary-electric-cyan-100',
  'active:bg-primary-electric-cyan-40',
  'hover:bg-nano-electric-cyan/[.1]',
  'hover:text-primary-dark-purple-100',
  'rounded-2xl',
  'mt-6',
]);

export const UserProfilePopover = () => {
  const { t } = useTranslation();
  const { logout } = useAuth();

  const [isProfilePopoverVisible, setIsProfilePopoverVisible] = useState(false);
  const { data: user, isLoading } = useUserProfileQuery(null);
  const { data: currentRole, isLoading: isLoadingCurrentRole } = useCurrentRoleQuery(null);

  const userIdentifier = user && getUserIdentifier(user);

  const { handleNanoBotOpen } = useNanoBotActions();

  usePendo(user?.id);

  const { navigateToOnboarding } = useNanoTourContext();

  const handlePopoverHide = useCallback(() => {
    setIsProfilePopoverVisible(false);
  }, []);

  const handleHelpCenterClick = useCallback(() => {
    setIsProfilePopoverVisible(false);
    handleNanoBotOpen();
  }, [handleNanoBotOpen]);

  const handleOnboardingClick = useCallback(() => {
    handlePopoverHide();
    navigateToOnboarding();
  }, [navigateToOnboarding, handlePopoverHide]);

  return (
    <>
      <UniversalBackground isOpen={isProfilePopoverVisible} />
      <Popover
        align={{ offset: [50, 0] }}
        arrow={false}
        overlayClassName="nano-popover__profile"
        placement="rightBottom"
        content={
          <div className="w-[380px] p-5 pt-10">
            <div className={nanoTwMerge(classes.iconContainer, classes.iconContainerAfter)}>
              <UserIcon className={classNames(classes.icon)} size={38} />
            </div>
            <Title className="mb-0 text-center" level={3}>
              {userIdentifier}
            </Title>
            <Paragraph className="text-center">{user?.email}</Paragraph>
            <Divider className="my-4 bg-primary-dark-purple-20" />
            <Paragraph>
              <span className="font-bold">{t('pages.userProfile.position')}: </span>
              {!isLoading && (user?.roles ? user.roles.join(', ') : 'User')}
            </Paragraph>
            <Divider className="my-4 bg-primary-dark-purple-20" />
            <div className="flex flex-row items-center">
              <MagicBulb />
              <Button variant="text" className="font-normal" onClick={handleOnboardingClick}>
                {t('pages.userProfile.launchOnboarding')}{' '}
              </Button>
            </div>
            <div className="flex flex-row items-center">
              <MessageSquare />
              <Button variant="text" className="font-normal" onClick={handleHelpCenterClick}>
                {t('pages.userProfile.helpCenter')}{' '}
              </Button>
            </div>
            <Divider className="mb-6 mt-4 bg-primary-dark-purple-20" />
            <div className="flow-root">
              <Button variant="text" className="float-left -ml-5" onClick={logout}>
                | ← {t('pages.userProfile.logout')}
              </Button>
              {!isLoadingCurrentRole && currentRole?.role_name !== RolesPermission.user && (
                <AdminPanelButton
                  buttonText={t('pages.userProfile.goToAdminPanel')}
                  onClick={handlePopoverHide}
                />
              )}
              <Button
                variant="primary"
                size="medium"
                className="float-right w-[119px] rounded-xl"
                onClick={handlePopoverHide}
              >
                {t('pages.userProfile.close')}
              </Button>
            </div>
          </div>
        }
        trigger="click"
        open={isProfilePopoverVisible}
        onOpenChange={setIsProfilePopoverVisible}
      >
        <Tooltip title={t('accountSettings.title')} placement="right">
          <IconButton
            data-tour={NANO_TOUR_IDS.profile}
            className={classNames(SIDEBAR_BTN_CLASSES)}
            variant="text"
            size="large"
          >
            <UserProfileIcon className="hover:scale-110" />
          </IconButton>
        </Tooltip>
      </Popover>
    </>
  );
};
