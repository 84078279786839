import { ROUTES } from 'routing/constants';

export const cardDetails = [
  {
    titleKey: 'adminPanel.home.keywordGroup.title',
    descriptionKey: 'adminPanel.home.keywordGroup.description',
    bgClass: 'bg-secondary-gradient',
    btnClass: 'bg-secondary-white/20 text-primary-electric-cyan-100',
    route: ROUTES.KEYWORD_GROUP,
  },
  {
    titleKey: 'adminPanel.home.customCategory.title',
    descriptionKey: 'adminPanel.home.customCategory.description',
    bgClass: 'bg-primary-gradient',
    btnClass: 'bg-primary-light-purple-20/70 text-primary-dark-purple-100',
    route: ROUTES.CUSTOM_CATEGORY_LIST,
  },
  {
    titleKey: 'adminPanel.home.company.title',
    descriptionKey: 'adminPanel.home.company.description',
    bgClass: 'bg-card-dark-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.COMPANY,
  },
  {
    titleKey: 'adminPanel.home.team.title',
    descriptionKey: 'adminPanel.home.team.description',
    bgClass: 'bg-primary-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.TEAM,
  },
  {
    titleKey: 'adminPanel.home.user.title',
    descriptionKey: 'adminPanel.home.user.description',
    bgClass: 'bg-total-reach-value-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.USERS,
  },
  {
    titleKey: 'adminPanel.home.exclusionList.title',
    descriptionKey: 'adminPanel.home.exclusionList.description',
    bgClass: 'bg-card-dark-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.EXCLUSION_LIST,
  },
  {
    titleKey: 'adminPanel.auditLog.title',
    descriptionKey: 'adminPanel.auditLog.description',
    bgClass: 'bg-primary-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.AUDIT,
  },
  {
    titleKey: 'adminPanel.searchByVector.listHeading',
    descriptionKey: 'adminPanel.searchByVector.description',
    bgClass: 'bg-total-reach-value-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.SEARCH_BY_VECTOR,
  },
  {
    titleKey: 'adminPanel.contexta.listHeading',
    descriptionKey: 'adminPanel.contexta.description',
    bgClass: 'bg-card-dark-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.CONTEXTA,
  },
  {
    titleKey: 'adminPanel.distanceChecker.mainPageHeading',
    descriptionKey: 'adminPanel.distanceChecker.description',
    bgClass: 'bg-primary-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.DISTANCE_CHECKER,
  },
  {
    titleKey: 'adminPanel.segmentGroup.segmentGroupListHeading',
    descriptionKey: 'adminPanel.segmentGroup.description',
    bgClass: 'bg-total-reach-value-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.SEGMENT_GROUP,
  },
  {
    titleKey: 'adminPanel.home.domainList.title',
    descriptionKey: 'adminPanel.home.domainList.description',
    bgClass: 'bg-card-dark-gradient',
    btnClass: 'bg-primary-dark-purple-100/50 text-secondary-white',
    route: ROUTES.DOMAIN_LIST,
  },
];
