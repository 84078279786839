import { expiredDate } from '../segment/list/expiredDateSlice';
import { auditLog } from './auditLog/auditLogSlice';
import { company } from './company/companySlice';
import { contexta } from './contexta/contextaSlice';
import { customCategory } from './customCategories/customCategorySlice';
import { emptyCustomCategory } from './customCategories/emptyCustomCategories/emptyCustomCategorySlice';
import { distanceCheckerReducer } from './distanceChecker/distanceCheckerSlice';
import { scrapeUrlReducer } from './distanceChecker/scrapeUrl/scrapeUrlSlice';
import { domainList } from './domainList/domainListSlice';
import { exclusionList } from './exclusionList/exclusionListSlice';
// import { emptyKeywordGroup } from './keywordGroup/emptyKeywordGroup/emptyKeywordGroupSlice';
import { keywordGroup } from './keywordGroup/keywordGroupSlice';
import { searchByVector } from './searchByVector/searchByVectorSlice';
import { segmentGroup } from './segmentGroup';
import { team } from './team';
import { roleAssign } from './users/assignRoleSlice';
import { user } from './users/usersSlice';

export const adminPanelReducers = {
  company,
  user,
  roleAssign,
  keywordGroup,
  // emptyKeywordGroup,
  emptyCustomCategory,
  customCategory,
  team,
  exclusionList,
  auditLog,
  expiredDate,
  searchByVector,
  segmentGroup,
  distanceChecker: distanceCheckerReducer,
  scrapeUrl: scrapeUrlReducer,
  contexta,
  domainList,
};
