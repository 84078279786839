/* eslint-disable quotes */
import { en as adminPanel } from 'modules/adminPanel/translations/en';

export const en = {
  translation: {
    adminPanel,
    common: {
      noDataText: 'No data',
      choose: 'Choose',
      back: 'Back',
      yes: 'Yes',
      no: 'No',
      clearAll: 'Clear All',
    },
    forms: {
      labels: {
        email: 'Email',
        password: 'Password',
        newPassword: 'New password',
        confirmPassword: 'Confirm password',
        optional: 'Optional',
        login: 'Login',
      },
      errors: {
        email: 'Please, provide a valid email',
        password: 'Please, type in your password',
        newPassword: 'New password has to contain at least 8 characters',
        confirmPassword: 'Passwords do no match',
        required: 'This field is required',
        nonPastDate: 'Please, choose a date that is not in the past',
        maxFiveDays: 'Please, choose a date max 5 days from today',
        nan: 'Please enter a number',
      },
      placeholders: {
        email: 'email@gmail.com',
        password: '********',
        search: 'Search...',
      },
      tooltip: {
        password: 'Password should be at least 8 characters long',
      },
      segment: {
        create: {
          title: 'Let’s create your segment!',
          assist:
            "I'm delighted to assist in crafting a customized intent segment tailored to your specific needs.",
          overview:
            'Please provide a concise overview of your targeting requirements or alternatively, upload a detailed brief outlining your desired criteria.',
          craft:
            "I'll then carefully craft a segment that perfectly aligns with your targeting objectives.",
          name: {
            label: 'Intent Segment Name',
            placeholder: 'Type Intent Segment Name',
            tooltip: 'Include Brand name, campaign identifier and period',
          },
          domainList: {
            label: 'Domain List',
            placeholder: 'Choose Domain Lists',
            tooltip: 'Choose a domain lists from the drop-down menu',
            include: 'Include',
            exclude: 'Exclude',
          },
          ssp: {
            label: 'SSP',
            placeholder: 'Choose SSP',
            tooltip: 'Choose a preferred SSP from the drop-down menu',
          },
          dsp: {
            label: 'DSP',
            placeholder: 'Choose DSP',
            tooltip: 'Choose one or more preferred DSP from the drop-down menu',
          },
          parentId: {
            label: 'Parent segment',
            placeholder: 'Choose parent segment',
          },
          group: {
            label: 'Group',
            placeholder: 'Choose group',
          },
          hideNameForPartner: {
            label: 'Hide name for partner',
          },
          dspSeatId: {
            label: 'DSP seat ID',
            placeholder: 'Choose DSP seat ID',
          },
          sspDealId: {
            label: 'SSP deal ID',
            placeholder: 'Choose SSP deal ID',
          },
          expiredDate: {
            label: 'Expiration Date',
            placeholder: 'Choose Expiration Date',
          },
          debugUntil: {
            label: 'Troubleshoot Until',
            placeholder: 'Choose Date Until',
          },

          geo: {
            label: 'GEO',
            placeholder: 'Choose Countries',
            tooltip:
              'Choose your market to understand reach opportunity. Disclaimer: Nano does not implement geo-restrictions, these should be included at DSP-level',
          },
          labels: {
            title: 'All Labels',
            placeholder: 'Start typing label name',
            notFoundLabel: 'Label not found. Press enter to add a new one',
          },
        },
        save: {
          title: 'Save your segment!',
        },
        automated: {
          placeholder:
            'Intended audiences? \nContextual relevance? \nBrand Safety considerations? \nKPIs?',
          upload: 'Upload',
          dragFiles: ' or drag and drop',
          unSupportedFileType:
            'Selected file type is not supported. Only DOC, DOCX, PDF files are allowed',
          formats: 'DOC, DOCX, PDF',
          uploadPlaceholder: '<0>Upload</0> or drag and drop <br>DOC, DOCX, PDF</br>',
        },
        updateExpiredDate: {
          title: 'Change Expired Date',
        },
      },
    },
    links: {
      forgotPassword: 'Forgot password',
      createNewSegment: 'Create New Segment',
      homepage: 'Back to Home Page',
    },
    buttons: {
      submit: 'Submit',
      logIn: 'Log In',
      cancel: 'Cancel',
      back: 'Back',
      next: 'Proceed',
      save: 'Proceed',
      saveAndProceed: 'Proceed',
      downloadAll: 'Download all',
    },
    charts: {
      emotionDistribution: {
        title: 'Emotion Distribution',
        value: 'Index',
      },
      sentimentDistribution: {
        title: 'Sentiment',
        negative: 'Negative',
        neutral: 'Neutral',
        positive: 'Positive',
      },
      intentSeasonality: {
        title: 'Intent Seasonality',
      },
      intentConfirmation: {
        title: 'Intent Confirmation',
        targetingGroup: 'Intent Targeting Group',
        keywords: 'Keywords',
        intent: 'Intent',
        entities: 'Entities',
      },
      scaleImpact: {
        title: 'Scale Impact',
      },
      geoLanguages: {
        title: 'Geo Distribution',
        reset: 'Reset',
        countries: 'Countries',
        languages: 'Languages',
      },
    },
    nanoBot: {
      placeholder: 'Type a message...',
      welcomeMessage: 'Hello, Im NanoQ! Im your personal nano assistant. How can I help you?',
      title: 'NanoQ',
      status: 'Always active',
    },
    accountSettings: {
      title: 'Account Settings',
    },
    pages: {
      home: {
        title: {
          firstLogin: 'Welcome to LIIFT',
          default: 'Welcome back,',
        },
        tooltip: 'Home',
        cardOne: {
          title: 'Build',
          description: 'Let us help you set up your perfect Intent-fueled strategy',
        },
        cardTwo: {
          title: 'Manage',
          description: 'Check out our LIIFT dashboard for a live view of your segments',
        },
        cardThree: {
          title: 'Report',
          description: 'Whether mid or post campaign, access detailed and comprehensive analytics',
        },
      },
      notFound: {
        title: "Oops! Sorry, but this page doesn't exist",
        description: 'You can go back to Home page or <1>contact us<1>',
      },
      university: {
        title: 'NANO University',
        hoverTitle: 'Nano University',
        tutorial: 'Platform Tutorial',
        downloadMaterials: 'Download Collateral',
        mouseoverGuide: 'Welcome to Mouseover guide!',
        FAQ: 'FAQ',
        achievements: 'Our Achievements',
        policies: `Nano’s policies and guidelines on data privacy and additional information
        can be found <1>here.<1>`,
      },
      library: {
        title: 'Intent Library',
        searchLabel: 'Search',
        tabs: {
          all: 'All',
          intentVerticals: 'Intent Verticals',
          intentPersonas: 'Intent Personas',
          holidaysAndEvents: 'Holidays & World Events',
          languages: 'Languages',
        },
        tableColumns: {
          segmentType: 'Segment Type',
          description: 'Description',
          languageCode: 'Language code',
          languageName: 'Language name',
        },
      },
      welcome: {
        welcomeTo: 'Welcome to NANO',
        readyTo: 'Ready to start?',
        logIn: 'Log In to Nano Account',
        loggingIn: 'Logging in to Nano Account',
        news: 'NANO News',
      },
      measurement: {
        title: 'Reporting',
        refresh: 'Refresh',
      },
      management: {
        title: 'Segment Management',
        newSegment: 'New Segment',
        searchPlaceholder: 'Search',
        addNewSegment: 'Add New Segment',
        manageLabels: {
          title: 'Manage Labels',
          label: 'New Label Name',
          placeholder: 'Type new label name',
          addLabel: 'Add Label',
          allLabels: 'All Labels',
          segmentLabels: 'Segment Labels',
          deleteDialogTitle: 'Delete this label?',
          deleteDialogSubTitle:
            'Are you sure you want to delete this label? It will be permanently deleted from the system and removed from other segments',
          saved: 'New label was added successfully! You can add it to your segment now',
        },
        groupBy: 'Grouped By:',
        groupByLabel: 'Labels:',
        groupByTeam: 'Teams:',
        groupByStatus: 'Status:',
        groupBySsp: 'Ssp:',
        groupByPartnerIntegration: 'Partner:',
        seeAll: 'See all',
        table: {
          segmentName: 'Intent Name',
          ssp: 'SSP',
          dsp: 'DSP',
          seatId: 'DSP seat ID',
          dealId: 'SSP deal ID',
          expiredDate: 'Expiration D.',
          impressions: 'Impressions',
          status: 'Status',
          label: 'Label',
          partnerIntegration: 'Integration',
          copy: 'Copy',
          deactivate: 'Deactivate Intent',
          activate: 'Activate Intent',
          delete: 'Delete Intent',
        },
        tooltipTitles: {
          expandList: 'Expand list',
          impressions: 'Last 30 days',
          status: {
            active: 'Ready for use',
            planning: 'Drafting in progress',
            pending: 'Awaiting confirmation',
            expired: 'No longer active',
            expiring: 'Nearing the end of its validity',
            deactivated: 'Deactivated',
          },
          copy: 'Duplicate this Segment',
          copyId: 'Click to copy the Intent Id',
          trackingTags: 'Tracking Tags',
          edit: 'Edit/Review Scale Impact',
        },
      },
      segmentModeSelection: {
        title: "Let's choose the type of intent targeting group creation",
        semanticSentence: 'Copilot',
        semanticDesc:
          'Allow us to utilize our AI technology to create tailored intents that align perfectly with your client`s specific brief, simplifying the process for you.',
        manualDesc:
          'For those how prefer a hands-on approach, over platform empowers you to manually craft intents, putting you in control of the process while still leveraging our AI capabilities for support.',
        manual: 'Take control',
      },
      userProfile: {
        position: 'Position',
        logout: 'Logout',
        close: 'Close',
        helpCenter: 'Help Center',
        launchOnboarding: 'Launch Onboarding',
        changePassword: 'Change Password',
        goToAdminPanel: 'Admin Panel',
      },
      segmentDetails: {
        generalInfo: 'General',
        targetingInfo: 'Targeting',
        targetingBuckets: 'Intent Targeting Groups',
        planning: 'Planning',
        totalImpressions: 'Impressions',
        intentConfirmation: 'Intent Confirmation',
        sentimentDistribution: 'Sentiment Distribution',
        emotionDistribution: 'Emotion Distribution',
        saveAsPlanning: 'Save as Planning',
        cancel: 'Cancel',
        close: 'Close',
        save: 'Save',
        saveAndProceed: 'Proceed',
        deactivate: 'Deactivate Intent',
        activate: 'Activate Intent',
        performance: {
          title: 'Performance',
          ctr: 'CTR',
          viewability: 'Viewability',
          view: 'View.',
          tiv: 'TIV',
          attentionMetric: 'Attention',
          attent: 'Attent.',
          addData: 'Add Data',
          noDataPlaceholder: 'CTR, Viewability, Attention or/and TIV can be displayed here',
        },
        targetingDescription: {
          title: 'Targeting Description',
          save: 'Save',
          cancel: 'Cancel',
          description: 'Description',
        },
      },
      segmentSave: {
        generalInfoTitle: 'Segment General Information',
        title: 'Great! You have created a new segment',
        description: 'You can view all the data for the new segment on the Management tab.',
      },
    },
    segment: {
      greeting: 'Great! Now let’s create your audience!',
      targetingOptions: {
        keywords: 'Keywords',
        intent: 'Intent',
        sentiment: 'Sentiment',
        language: 'Languages',
        entity: 'Entities',
        emotions: 'Emotions',
        personas: 'Personas',
        sensitiveSubjects: 'Sensitive Subjects',
        intentPrompt: 'Intent Prompt',
      },
      include: 'Include',
      exclude: 'Exclude',
      keywords: {
        enterKeywords: 'Enter Keywords',
        enterIntentPrompt: 'Enter Intent Prompt',
        uploadKeywords: 'Upload Keywords',
        manuallyEnteredKeywords: 'Manually Entered Keywords',
        manuallyEnteredIntentPrompts: 'Manually Entered Intent Prompts',
        uploadedKeywords: 'Uploaded Keywords',
        keywordInputPlaceholder: 'Enter keywords...',
        intentPromptInputPlaceholder: 'Enter intent prompts...',
        uploadPlaceholder: '<0>Upload</0> or drag and drop XLSX files',
        importFromFile: 'Import From File',
        xlsxTemplate: 'XLSX Template',
        exportKeywords: 'Export',
        fileDeleteConfirmationTitle: 'Are you sure you want to delete the file?',
        fileDeleteConfirmationDesc: 'All keywords form the file will be deleted.',
        unSupportedFileType:
          'Selected file type is not supported for file {{fileName}}. Only XLSX files are allowed',
        exceededLimit:
          "Sorry, we can't process this entry, because a total limit of {{limit}} keywords both uploaded and entered manually can't be exceeded",
        duplicates:
          'Following duplicate keywords were found: {{duplicates}}, they will not be added to keywords',
        andMore: 'and {{more}} more',
      },
      intent: {
        emptySelection: 'You have not selected any intent yet',
        emptySearch: "Sorry, we couldn't find any intent that match your search",
        exceededLimit:
          "Sorry, we can't process this entry, because a total limit of {{limit}} intent prompts",
        duplicates:
          'Following duplicate intent prompts were found: {{duplicates}}, they will not be added to intent prompts',
        andMore: 'and {{more}} more',
      },
      entity: {
        chooseEntity: 'Choose Entity',
        noData: 'No Data',
        description:
          'Entities allow us to target relevant semantic elements based on the below categorisations',
        general: 'General',
        brand: 'Brand',
        product: 'Product',
        people: 'People',
        place: 'Place',
        facility: 'Facility',
        location: 'Location',
        event: 'Event',
        workOfArt: 'Work of Art',
        norp: 'NORP',
        placeholder:
          'Please add the specifications (Separate the words by new line. Use (Shift + Enter) to go to new line.',
      },
      sentiment: {
        chooseSentiment: 'Choose Sentiment',
        description:
          'We use NLP to understand the sentiment of the content your ad will appear on.',
        disclaimer:
          'Disclaimer: choosing either will reduce scale, please monitor impression volumes',
        positiveDescription: 'only appear across content with a generally positive tone',
        negativeDescription: 'only appear across content with a generally negative tone',
        positive: 'Positive',
        negative: 'Negative',
      },
      personas: {
        personas: 'Choose Personas',
        description:
          'Connect your brand with the right audiences using Nano Intent Mapping, blended with and verified by panels of real people, without the use of cookies',
        noDataToShow: 'No data to show',
      },
      emotions: {
        emotions: 'Choose Emotions',
        description:
          'We use NLP to understand the emotional layer of the content at sentence and page level. This constitutes a further breakdown of the sentiment layer attached to any piece of content. To run across all content, please leave all unchecked.',
        disclaimer: 'Disclaimer: choosing any will reduce scale, please monitor impression volumes',
        love: 'Love',
        joy: 'Joy',
        surprise: 'Surprise',
        anger: 'Anger',
        fear: 'Fear',
        sadness: 'Sadness',
        noDataToShow: 'No data to show',
      },
      sensitiveSubjects: {
        description:
          'If your targeting would benefit from including any of the following sensitive categories, please leave them checked.',
        unselectAll: 'Unselect all',
        selectAll: 'Select all',
      },
      language: {
        chooseLanguage: 'Choose Language',
        description: 'We can activate across any market, any script and 100 languages',
        searchPlaceholder: 'Search',
        top5: 'Top 5 Languages',
        selectAll: 'Select All',
        unselectAll: 'Unselect All',
      },
      buckets: {
        title: 'Create Additional Intent Targeting Groups',
        hint: 'Choose to save your segment as it is or add an additional intent targeting group. The relationship within each intent targeting group uses “and” Boolean logic, while between intent targeting groups it uses “or” Boolean logic.',
        bucketLabel: 'Intent Targeting Group',
        addNewBucket: 'Add Intent Targeting Group',
        or: 'or',
      },
      modeSwitcher: {
        automated: 'Copilot',
        manual: 'Take control',
      },
      cancellation: {
        title: 'Are you sure you want to leave without saving the changes in the Segment?',
        description: 'By cancelling the process all your changes will be deleted.',
      },
      bucketDeletion: {
        title: 'Are you sure you want to delete the intent targeting group?',
        description: 'When the intent targeting group is deleted, this action is not reversible.',
      },
      deletion: {
        title: 'Are you sure you want to delete the intent?',
        description: 'When the intent is deleted, this action is not reversible.',
      },
      tagList: {
        yourSelection: 'Your selection',
        empty: 'You have not selected anything yet',
      },
      dateRangeSwitcher: {
        monthly: 'Monthly',
        weekly: 'Weekly',
        daily: 'Daily',
      },
    },
    errors: {
      segmentListFetchFailed: 'Failed to load segment list. Please, try again later.',
      segmentDetailsFetchFailed: 'Failed to load segment data. Please, try again later.',
      emptySegmentCreationFailed: 'Failed to create new segment. Please, try again later.',
      segmentSaveFailed:
        "We can't save your segment. Please check your internet connection and try again.",
    },
    info: {
      segmentActivated: 'The intent will be activated as soon as you save the changes.',
      segmentDeactivated: 'The intent will be deactivated as soon as you save the changes.',
      copied: 'Copied!',
    },
    errorBoundaryTitle: 'Oops! Sorry, but unexpected error occurred.',
    trackingTags: {
      title: 'Install Your Tracking Tags',
      sections: {
        general: {
          title: 'General',
          viewability: 'Viewability & Impression tag',
          click: 'Click tag',
          impression: 'Impression',
          vastClick: 'Vast Click tag',
          attrClick: 'Privacy Sandbox Click tag',
          attrImpression: 'Privacy Sandbox Impression tag',
          attrConversion: 'Privacy Sandbox Conversion tag',
        },
        videoTracking: {
          title: 'Video Tracking',
          complete: 'Complete',
          firstQuartile: 'First quartile',
          fullscreen: 'Full screen',
          midPoint: 'Mid point',
          mute: 'Mute',
          pause: 'Pause',
          resume: 'Resume',
          start: 'Start',
          thirdQuartile: 'Third quartile',
          unmute: 'Unmute',
        },
      },
      copied: 'Copied!',
      copyTag: 'Copy tag',
      copyUrl: 'Copy url',
    },
  },
};
