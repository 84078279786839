import { IconProps } from 'feather-icons-react';

export const UniversityIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="34"
    height="28"
    viewBox="0 0 34 28"
    fill="none"
    className={className}
  >
    <path
      d="M27.7758 11.3226C27.8174 11.1531 27.8388 10.9809 27.8388 10.8064C27.8388 8.24099 23.2172 6.16129 17.5162 6.16129C11.8152 6.16129 7.1936 8.24099 7.1936 10.8064C7.1936 10.9809 7.21497 11.1531 7.2566 11.3226H7.1936V20.6129H7.2566C7.82715 22.9354 12.2029 24.7419 17.5162 24.7419C22.8295 24.7419 27.2052 22.9354 27.7758 20.6129H27.8388V11.3226H27.7758Z"
      fill="url(#paint0_linear_1245_2641)"
      fillOpacity="0.7"
    />
    <g filter="url(#filter0_b_1245_2641)">
      <path
        d="M32.9022 7.59687C34.3659 8.3718 34.3659 9.6282 32.9022 10.4031L19.6504 17.4188C18.1866 18.1937 15.8134 18.1937 14.3496 17.4188L1.09782 10.4031C-0.365939 9.6282 -0.365939 8.3718 1.09782 7.59687L14.3496 0.581197C15.8134 -0.193732 18.1866 -0.193732 19.6504 0.581197L32.9022 7.59687Z"
        fill="#00FFFA"
        fillOpacity="0.5"
      />
    </g>
    <g filter="url(#filter1_b_1245_2641)">
      <path
        d="M17.5161 8.74194L25.7742 13.1659V19.0645"
        stroke="white"
        strokeOpacity="0.7"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <g filter="url(#filter2_b_1245_2641)">
      <path
        d="M22.6775 21.6452C22.6775 19.9349 24.064 18.5484 25.7743 18.5484C27.4846 18.5484 28.871 19.9349 28.871 21.6452V27.2581C28.871 27.5788 28.6111 27.8387 28.2904 27.8387H23.2581C22.9375 27.8387 22.6775 27.5788 22.6775 27.2581V21.6452Z"
        fill="white"
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1245_2641"
        x="-1"
        y="-1"
        width="36"
        height="20"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2641" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2641"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_1245_2641"
        x="13.0161"
        y="4.24185"
        width="17.2581"
        height="19.3227"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2641" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2641"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_1245_2641"
        x="21.6775"
        y="17.5484"
        width="8.1936"
        height="11.2903"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2641" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2641"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1245_2641"
        x1="10.2904"
        y1="29.9032"
        x2="10.2904"
        y2="6.16129"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
    </defs>
  </svg>
);
