import { IconProps } from 'feather-icons-react';

export const ManagementIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="35"
    height="37"
    viewBox="-4 -4 35 37"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_b_1546_307)">
      <rect width="26" height="21" rx="6" fill="url(#paint0_linear_1546_307)" fillOpacity="0.2" />
    </g>
    <g filter="url(#filter1_b_1546_307)">
      <rect
        y="4"
        width="26"
        height="21"
        rx="6"
        fill="url(#paint1_linear_1546_307)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter2_b_1546_307)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6 7C2.68629 7 0 9.68629 0 13V15V23C0 26.3137 2.68629 29 6 29H20C23.3137 29 26 26.3137 26 23V15C26 11.6863 23.3137 9 20 9H14.8293C14.4175 7.83481 13.3062 7 12 7H6Z"
        fill="url(#paint2_linear_1546_307)"
        fillOpacity="0.7"
      />
    </g>
    <g filter="url(#filter3_bd_1546_307)">
      <circle cx="21" cy="23" r="8" fill="white" fillOpacity="0.4" shapeRendering="crispEdges" />
    </g>
    <circle cx="20.9974" cy="22.9974" r="4.52624" stroke="white" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M18.9413 23.4974C19.2175 23.4974 19.4413 23.2735 19.4413 22.9974C19.4413 22.7212 19.2175 22.4974 18.9413 22.4974H15.5C15.2239 22.4974 15 22.7212 15 22.9974C15 23.2735 15.2239 23.4974 15.5 23.4974L18.9413 23.4974ZM26.4948 23.4974C26.771 23.4974 26.9948 23.2735 26.9948 22.9974C26.9948 22.7212 26.771 22.4974 26.4948 22.4974H23.0535C22.7773 22.4974 22.5535 22.7212 22.5535 22.9974C22.5535 23.2735 22.7773 23.4974 23.0535 23.4974L26.4948 23.4974Z"
      fill="white"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.4973 20.9413C20.4973 21.2175 20.7212 21.4413 20.9973 21.4413C21.2735 21.4413 21.4973 21.2175 21.4973 20.9413L21.4973 17.5C21.4973 17.2239 21.2735 17 20.9973 17C20.7212 17 20.4973 17.2239 20.4973 17.5L20.4973 20.9413ZM20.4973 28.4948C20.4973 28.7709 20.7212 28.9948 20.9973 28.9948C21.2735 28.9948 21.4973 28.7709 21.4973 28.4948L21.4973 25.0534C21.4973 24.7773 21.2735 24.5534 20.9973 24.5534C20.7212 24.5534 20.4973 24.7773 20.4973 25.0534L20.4973 28.4948Z"
      fill="white"
    />
    <defs>
      <filter
        id="filter0_b_1546_307"
        x="-3"
        y="-3"
        width="32"
        height="27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1546_307" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1546_307"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_1546_307"
        x="-3"
        y="1"
        width="32"
        height="27"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1546_307" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1546_307"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_1546_307"
        x="-2"
        y="5"
        width="30"
        height="26"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1546_307" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1546_307"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_bd_1546_307"
        x="7"
        y="9"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1546_307" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.3 0"
        />
        <feBlend
          mode="color-burn"
          in2="effect1_backgroundBlur_1546_307"
          result="effect2_dropShadow_1546_307"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1546_307"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1546_307"
        x1="-2"
        y1="1.98355e-07"
        x2="17.2137"
        y2="27.1188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1546_307"
        x1="-2"
        y1="4"
        x2="17.2137"
        y2="31.1188"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1546_307"
        x1="6"
        y1="8.5"
        x2="16.5448"
        y2="28.158"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="0.96599" stopColor="#8C48BF" />
      </linearGradient>
    </defs>
  </svg>
);
