import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { ChatArea } from 'components/NanoBot/components/ChatArea';
import { NanoBotHeader } from 'components/NanoBot/components/NanoBotHeader';
import { NanoBotMessageInput } from 'components/NanoBot/components/NanoBotMessageInput';
import { IconButton, Popover, Tooltip } from 'components/common';
import { NanoBotImg } from 'components/common/nanoBot';

import { getIsNanoBotOpen } from 'modules/nanoBot/nanoBotSlice';
import { useNanoBotActions } from 'modules/nanoBot/useNanoBotActions';
import { NANO_TOUR_IDS } from 'modules/tour';

const sidebarBtnClasses = classNames([
  'text-primary-electric-cyan-100',
  'active:bg-primary-electric-cyan-40',
  'hover:bg-primary-electric-cyan-100',
  'hover:text-primary-dark-purple-100',
  'rounded-2xl',
  'bg-primary-gradient',
]);

export const NanoBot = () => {
  const { t } = useTranslation();
  const isOpenNanoBot = useSelector(getIsNanoBotOpen);
  const { handleNanoBotOpen } = useNanoBotActions();

  return (
    <Popover
      placement="rightBottom"
      align={{ offset: [50, 95] }}
      content={
        <div className="m-[-12px] h-[529px] w-[623px] rounded-3xl bg-primary-dark-purple-100">
          <NanoBotHeader />
          <ChatArea />
          <NanoBotMessageInput />
        </div>
      }
      trigger="click"
      open={isOpenNanoBot}
      onOpenChange={handleNanoBotOpen}
    >
      <Tooltip title={t('nanoBot.title')} placement="right">
        <IconButton
          data-tour={NANO_TOUR_IDS.bot}
          className={classNames(sidebarBtnClasses)}
          size="large"
        >
          <NanoBotImg className="h-[36px] hover:scale-110" />
        </IconButton>
      </Tooltip>
    </Popover>
  );
};
