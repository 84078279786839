export const en = {
  title: 'You are now in Admin Panel,',
  backToHome: 'Back To Admin Panel',
  goToAdminPanel: 'Admin Panel',
  common: {
    clearAll: 'Clear All',
    noDataText: 'No data',
    clear: 'Clear',
  },
  forms: {
    errors: {
      required: 'This field is required.',
    },
  },
  errors: {
    unknownError: 'An unknown error occurred',
    generalError: 'Something went wrong. Please try again later.',
  },
  home: {
    customCategory: {
      title: 'Custom Category',
      description: 'Lets create and edit custom categories',
    },
    keywordGroup: {
      title: 'Keyword group',
      description: 'Lets create and edit keyword groups',
    },
    company: {
      title: 'Company',
      description: 'Lets create and edit companies',
    },
    user: {
      title: 'User',
      description: 'Lets create and edit LIIFT users',
    },
    team: {
      title: 'Team',
      description: 'Lets create and edit LIIFT teams',
    },
    exclusionList: {
      title: 'Exclusion List',
      description: 'Create and manage exclusion list',
    },
    domainList: {
      title: 'Domain Lists',
      description: 'Create and manage domain lists',
    },
  },
  company: {
    errors: {
      companyListFetchFailed: 'Failed to load company list. Please, try again later.',
      companySaveFailed: 'Failed to save company. Please try later',
      companyDeleteFailed: 'Failed to delete company. Please try later',
      companyUpdateFailed: 'Failed to update company. Please try later',
    },
    success: {
      companySaveSuccess: 'Company successfully saved!',
    },
    newCompany: 'New Company',
    deleteCompanyTooltip: 'Delete',
    editCompanyTooltip: 'Edit',
    companyListHeading: 'Companies',
    dialog: {
      editHeading: 'Edit Company',
      createHeading: 'New Company',
      name: 'Name',
      address: 'Address',
      deleteCompanyConfirmationTitle: 'Delete company',
      deleteCompanyConfirmationSubTitle: 'Are you sure you want to delete this company?',
      submitButton: 'Submit',
      cancelButton: 'Cancel',
    },
    table: {
      name: 'Name',
      address: 'Address',
      creationDate: 'Creation Date',
      actionOptions: {
        edit: 'Edit Company',
        delete: 'Delete Company',
      },
    },
  },
  user: {
    greeting: 'Create User',
    edit: 'Edit User',
    userListHeading: 'LIIFT Users',
    roleAssign: 'Role Assign',
    success: {
      userSaveSuccess: 'Your user has been saved successfully.',
      userUpdateSuccess: 'Your user has been updated successfully.',
    },
    errors: {
      userListFetchFailed: 'Failed to load user list. Please, try again later.',
      userDetailsFetchFailed: 'Failed to load user data. Please, try again later.',
      passwordNotMatch: 'Password do not match.',
      passwordLength: 'Password must be at least 8 characters long.',
      invalidEmail: 'Invalid email.',
      update: 'Failed to update User. Please try later.',
      save: 'Failed to save User. Please try later.',
      delete: 'Failed to delete User. Please try later.',
      changePassword: 'Failed to change password. Please try later.',
    },
    yourSelection: 'Your Selection',
    emptySelection: 'You have not selected any company yet.',
    buttons: {
      cancel: 'Cancel',
      next: 'Next',
      back: 'Back',
      save: 'Save',
      update: 'Update',
      changePassword: 'Change Password',
    },
    groupByCompany: 'Company:',
    navigateListUser: 'User List',
    formPlaceholder: {
      name: 'Name',
      surname: 'Surname',
      email: 'Email',
      password: 'Password',
      confirmPass: 'Confirm Password',
      newPass: 'New Password',
      oldPass: 'Old Password',
      company: 'Company',
      labels: 'Labels',
      labelsTextarea: 'Enter labels separated by new line...',
      team: 'Team',
      role: 'Role',
      changePass: 'Change Password',
    },
    cancellationCreate: {
      title: 'Are you sure you want to cancel the User creation?',
      description: 'By canceling the process the user and all its data will be deleted.',
    },
    cancellationEdit: {
      title: 'Are you sure you want to cancel the User edit?',
      description: 'By canceling the process the user and all its data won`t be saved.',
    },
    newUser: 'New User',
    deleteUserTooltip: 'Delete',
    editUserTooltip: 'Edit',
    dialog: {
      editHeading: 'Edit User',
      createHeading: 'New User',
      name: 'Name',
      email: 'Email',
      deleteUserConfirmationTitle: 'Delete user',
      deleteUserConfirmationSubTitle: 'Are you sure you want to delete this user?',
    },
    table: {
      name: 'User Name',
      email: 'User Email',
      company: 'Company',
      team: 'Team',
      actionOptions: {
        edit: 'Edit User',
        delete: 'Delete User',
      },
    },
  },
  keywordGroup: {
    title: 'Keyword Group',
    newKeywordGroup: 'Keyword Group',
    searchPlaceholder: 'Search',
    addNewKeywordGroup: 'Add New Keyword Group',
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
      copy: 'Copy',
    },
    table: {
      keywordGroupName: 'Keyword Group Name',
      usedBy: 'Used By Categories',
      actions: 'Actions',
      copy: 'Copy',
      actionOptions: {
        copy: 'Copy Keyword Group',
        delete: 'Delete Keyword Group',
        edit: 'Edit Keyword Group',
      },
    },
    form: {
      title: 'Great! Now let’s create your keyword group!\n',
      chooseName: 'Keyword Group Name',
      chooseKeywords: 'Choose Keywords',
      enterKeywordsPlaceholder: 'Enter your keywords',
      enterNamePlaceholder: 'Enter the name',
      description:
        'Please upload (or manually enter with a comma separation) your KW lists for keyword group',
      requiredKeywords: 'This field is required. Please enter at least one keyword.',
      requiredName: 'This field is required. Please enter the name.',
      recurseNotFound: 'Oops! Sorry, but this keyword group doesn\'t exist',
    },
    cancellationCreate: {
      title: 'Are you sure you want to cancel the Keyword Group creation?',
      description:
        'By canceling the process, the keyword group and all its unsaved data will be deleted. ' +
        'Please ensure you have saved any changes before proceeding.',
    },
    cancellationEdit: {
      title: 'Are you sure you want to cancel the Keyword Group edit?',
      description:
        'By canceling the process, the keyword group and all its unsaved data will be deleted. ' +
        'Please ensure you have saved any changes before proceeding.',
    },
    deleting: {
      title: 'Are you sure you want to delete the Keyword Group?',
      description:
        'This action will permanently delete the keyword group and all associated data. ' +
        'This process cannot be undone.',
    },
    coping: {
      title: 'Are you sure you want to copy the Keyword Group?',
      description:
        'This will create a duplicate of the selected keyword group with all its associated keywords.',
    },
    errors: {
      keywordGroupRecurseNotFound: 'Failed to load keyword group. Recurse not found.',
      keywordGroupListFetchFailed: 'Failed to load keyword group list. Please, try again later.',
      keywordGroupDetailsFetchFailed: 'Failed to load keyword group data. Please, try again later.',
      emptyKeywordGroupCreationFailed:
        'Failed to create new keyword group. Please, try again later.',
      keywordGroupSaveFailed:
        'We can\'t save your keyword group. Please check ' +
        'your internet connection and try again.',
      keywordGroupDeleteFailed:
        'We can\'t remove this keyword group. Please check ' +
        'your internet connection and try again.',
    },
    success: {
      keywordGroupSaveSuccess: 'Your keyword group has been saved successfully.',
    },
    deleteKeywordGroupTooltip: 'Delete Keyword Group',
    copyKeywordGroupTooltip: 'Duplicate Keyword Group',
    editKeywordGroupTooltip: 'Edit Keyword Group',
    keywords: {
      xlsxTemplate: 'XLSX Template',
      download: 'Download',
      importFromFile: 'Import From File',
    },
    backToKeywordGroupList: 'Back To Keyword Group List',
  },
  customCategories: {
    greeting: 'Great! Now let’s create your custom category!',
    title: 'Custom Categories',
    newCategory: 'Custom Category',
    searchPlaceholder: 'Search',
    selectAll: 'Select All',
    unselectAll: 'Unselect All',
    namePlaceholder: 'Name',
    edit: 'Edit',
    startTyping: 'Start typing your description',
    addNewCategory: 'Add Custom Category',
    parentPlaceholder: 'Parent Custom Category',
    emptySelection: 'You have not selected any keyword group yet',
    yourSelection: 'Your selection',
    chooseKeywordGroups: 'Choose Your Keyword Groups',
    keywordTransferLeftColumn: 'All Keyword Groups',
    keywordTransferRightColumn: 'Selected Keyword Group(s)',
    description: 'Description',
    recurseNotFound: 'Oops! Sorry, but this custom category doesn\'t exist',
    table: {
      categoryName: 'Custom Category Name',
      id: 'ID',
      description: 'Description',
      parent: 'Parent',
      copy: 'Copy',
      actionOptions: {
        edit: 'Edit Custom Category',
        delete: 'Delete Custom Category',
      },
    },
    groupByParent: 'Parent:',
    tooltipTitles: {
      delete: 'Delete Category',
      edit: 'Edit Category',
    },
    cancellationCreate: {
      title: 'Are you sure you want to cancel the Custom Category creation?',
      description:
        'By canceling the process, the custom category and all its unsaved data will be deleted. ' +
        'Please ensure you have saved any changes before proceeding.',
    },
    cancellationEdit: {
      title: 'Are you sure you want to cancel the Custom Category edit?',
      description:
        'By canceling the process, the custom category and all its unsaved data won`t be updated. ' +
        'Please ensure you have saved any changes before proceeding.',
    },
    delete: {
      title: 'Are you sure you want to delete the Custom Category',
      description:
        'This action will permanently delete the custom category and all associated data. This process cannot be undone.',
    },
    firstTier: 'Tier 1 Custom Category',
    modeSwitcherPersonas: {
      customCategory: 'Custom Category List',
      keywordGroups: 'New Keyword Group',
    },
    success: {
      customCategorySaveSuccess: 'Your custom category has been saved successfully.',
    },
    errors: {
      customCategoryRecurseNotFound: 'Failed to load custom category. Recurse not found.',
      customCategoryListFetchFailed:
        'Failed to load custom category list. Please, try again later.',
      customCategoryDeleteFailed: 'Something went wrong. We can\'t delete your custom category.',
      keywordGroupsListFetchFailed: 'Failed to load keyword groups list. Please, try again later.',
      emptyCustomCategoryCreationFailed: 'Failed to create new category. Please, try again later.',
      customCategorySaveFailed:
        'We can\t save your custom category. Please check your internet connection and try again.',
      forms: {
        required: 'This field is required.',
        description: 'Description is required.',
        keywordGroups: 'Please select at least one keyword group.',
        recurseNotFound: 'Oops! Sorry, but this custom category doesn\'t exist',
      },
    },
    buttons: {
      cancel: 'Cancel',
      save: 'Save',
    },
  },
  team: {
    errors: {
      teamListFetchFailed: 'Failed to load team list. Please, try again later.',
      teamSaveFailed: 'Failed to save team. Please try later',
      teamDeleteFailed: 'Failed to delete team. Please try later',
      teamUpdateFailed: 'Failed to update team. Please try later',
    },
    success: {
      teamSaveSuccess: 'Team successfully saved!',
    },
    newTeam: 'New Team',
    deleteTeamTooltip: 'Delete',
    editTeamTooltip: 'Edit',
    teamListHeading: 'Teams',
    dialog: {
      editHeading: 'Edit Team',
      createHeading: 'New Team',
      name: 'Name',
      company: 'Company',
      deleteTeamConfirmationTitle: 'Delete team',
      deleteTeamConfirmationSubTitle: 'Are you sure you want to delete this team?',
      usersLabel: 'Users',
      selectUsersText: 'Choose users',
      submitButton: 'Submit',
      cancelButton: 'Cancel',
    },
    table: {
      name: 'Name',
      address: 'Address',
      creationDate: 'Creation Date',
      actionOptions: {
        edit: 'Edit Team',
        delete: 'Delete Team',
      },
    },
  },
  auditLog: {
    title: 'Audit Log',
    description: 'Lets search audit log',
    search: 'Search by user or entity id',
    searchButton: 'Search',
    methodFilter: 'Choose Method',
    errorOneIsRequired: 'One more field is required.',
    errors: {
      auditLogSearchFailed: 'Something went wrong. Please try again later.',
      auditLogSingleLineFailed: 'Failed to load log. Please try again later.',
    },
    searchFormLabel: {
      entity: 'Entity Type',
      entityId: 'Entity ID',
      date: 'Date',
      user: 'User',
    },
    searchFormPlaceholder: {
      user: 'Choose User',
      entity: 'Choose Entity',
      id: 'ID',
    },
    searchFormLabelCollapsed: {
      entity: 'Entity',
      entityId: 'ID',
      from: 'From',
      to: 'To',
    },
    logCompare: {
      title: 'Audit Log Compare',
      oldData: 'Previous Data',
      newData: 'New Data',
    },
  },
  exclusionList: {
    listHeading: 'Exclusion list',
    newItems: 'Add',
    deleteList: 'Clear list',
    dialog: {
      addHeading: 'Add new urls',
      deleteELConfirmationTitle: 'Delete Exclusion list item',
      deleteELConfirmationSubtitle: 'Are you sure you want to delete this item?',
      deleteAllELConfirmationTitle: 'Delete ALL Exclusion list items?',
      deleteAllELConfirmationSubtitle: 'Are you sure you want to delete ALL Exclusion list items!?',
      cancelButton: 'Cancel',
      submitButton: 'Submit',
      placeholder:
        'Please add urls (Separate urls by new line. Use (Shift + Enter) to go to new line )',
    },
    table: {
      url: 'Url',
      createdDate: 'Created Date',
      actionOptions: {
        delete: 'Delete Exclusion list item',
      },
    },
    errors: {
      exclusionListSaveFailed: 'Failed to save exclusion list. Please try later',
      exclusionListItemDeleteFailed: 'Failed to delete exclusion list item. Please try later',
      exclusionListDeleteFailed: 'Failed to delete exclusion list. Please try later',
      exclusionListFetchFailed: 'Failed to load exclusion list. Please, try again later.',
    },
    validators: {
      required: 'Please enter urls separated by new line!',
    },
  },
  searchByVector: {
    listHeading: 'Search By Vector',
    description: 'URL search by Vector',
    searchBttn: 'Search',
    placeholder: {
      keywords: 'Keyword list or phrase',
      matchingUrls: 'Matching URLs',
      minMaxWeight: 'Min/Max Weight',
      language: 'Language',
      minSimilarity: 'Min Similarity',
      maxSimilarity: 'Max Similarity',
      numOfUrls: 'URLs per result',
      type: 'Core type',
    },
    table: {
      url: 'URL',
      language: 'Language',
      accuracy: 'Accuracy',
      vector: 'Vector',
      similarity: 'Similarity',
      categoriesMatch: 'Cat. match',
    },
    percentageOfMatchedURLs: 'Percentage Of Matched URLs: ',
    contentParser: 'Content Parser',
    errors: {
      failedSearch: 'Failed to search by vector. Please try later',
    },
  },
  contexta: {
    listHeading: 'Contexta Analysis By Publisher',
    description: 'Get url publisher data or insert new data',
    title: 'Contexta Analisys By Publisher',
    url: 'URL',
    ttl: 'Time To Live',
    language: 'Language',
    accuracy: 'Accuracy',
    adClutter: 'Ad Clutter',
    categories: 'Categories',
    subcategories: 'Subcategories',
    weight: 'Weight',
    status: 'Status',
    sentiment: 'Sentiment',
    matchedTime: 'Matched Time',
    contentValidDays: 'Content Valid Days',
    contentLength: 'Content Length',
    keywordsVector: 'Keywords Vector',
    keywords: 'Keywords',
    keywordsPlaceholder: 'Enter your JSON here...',
    keywordsVectorPlaceholder: 'Enter keywords vector separated with new line...',
    emotions: 'Emotions',
    metaDescription: 'Meta Description',
    metadataTags: 'Metadata Tags',
    matchedSeg: 'Matched Seg.',
    useBaseUrl: 'Use Base Url',
    weightedKeywords: 'Weighted Keywords',
    h1: 'h1',
    h2: 'h2',
    numberOfWords: 'Number Of Words',
    buttons: {
      get: 'Get',
      insert: 'Insert',
      next: 'Next',
      cancel: 'Cancel',
      back: 'Back',
    },
    success: {
      insertSuccess: 'Successfully insert url.',
    },
    errors: {
      required: 'This field is required.',
      invalidLength: 'The length of keyword vectors must be at least 384.',
      invalidNumber: 'Invalid number.',
      invalidRange: 'Values must be between -1 and 1.',
      maxLengthEmotions: 'You can select a maximum of 3 emotions.',
      invalidJSON:
        'Invalid JSON: Please check for syntax errors, such as missing commas, brackets, or quotes.',
      failedGetContexta: 'Failed to get contexta. Please try later.',
      failedInsert: 'Failed to insert contexta. Please try later',
      invalidUrl: 'Url not valid.',
    },
    urlPublisherData: 'URL Publisher Data',
  },
  segmentGroup: {
    searchPlaceholder: 'Search',
    errors: {
      segmentGroupListFetchFailed: 'Failed to load segment group list. Please, try again later.',
      segmentGroupSaveFailed: 'Failed to save segment group. Please try later',
      segmentGroupDeleteFailed: 'Failed to delete segment group. Please try later',
      segmentGroupUpdateFailed: 'Failed to update segment group. Please try later',
    },
    success: {
      segmentGroupSaveSuccess: 'Segment group successfully saved!',
    },
    newSegmentGroup: 'New Group',
    deleteSegmentGroupTooltip: 'Delete',
    editSegmentGroupTooltip: 'Edit',
    segmentGroupListHeading: 'Segment groups',
    dialog: {
      editHeading: 'Edit Segment group',
      createHeading: 'New Segment group',
      name: 'Name',
      ssp: 'Ssp',
      deleteSegmentGroupConfirmationTitle: 'Delete segment group',
      deleteSegmentGroupConfirmationSubTitle: 'Are you sure you want to delete this segment group?',
      usersLabel: 'Users',
      selectUsersText: 'Choose users',
      submitButton: 'Submit',
      cancelButton: 'Cancel',
      sspGroupId: 'Ssp group id',
      price: 'Price',
      priceValid: 'Must be a number',
    },
    table: {
      name: 'Name',
      ssp: 'Ssp',
      sspGroupId: 'Ssp group id',
      price: 'Price',
      actionOptions: {
        edit: 'Edit Segment group',
        delete: 'Delete Segment group',
      },
    },
    description: 'Create and manage segment groups',
  },
  distanceChecker: {
    mainPageHeading: 'Url distance checker',
    description: 'Check distance between segment vectors and url',
    urlPlaceholder: 'Enter url',
    segmentPlaceholder: 'Choose segment',
    checkButton: 'Check',
    table: {
      vectorIndex: 'Vector Index',
      distance: 'Distance',
      targetType: 'Target type',
      targetId: 'Target Id',
      keywords: 'Intent Prompt',
    },
    errors: {
      failed: 'Failed to load data. Please try again later.',
    },
    charts: {
      scoredCategories: {
        catTitle: 'Categories',
        subCatTitle: 'Subcategories',
        xAxisLabel: 'Score',
      },
    },
    generalInfo: {
      language: 'Language',
      status: 'Status',
      validDaysOfContent: 'Valid days of content',
      accuracy: 'Accuracy',
    },
  },
  scrapeUrl: {
    errors: {
      failed: 'Failed to load data. Please try again later.',
    },
    info: {
      content: 'Content',
      length: 'Length',
      adClutter: 'Ad clutter',
      tokenCount: 'Token count',
      language: 'Language',
      time: 'Time',
      traceId: 'Trace Id',
      metadata: 'Metadata',
      title: 'Title',
      author: 'Author',
      description: 'Description',
      siteName: 'Site name',
      tags: 'Tags',
      headings: 'Headings',
    },
  },
  domainList: {
    listHeading: 'Domain list',
    newItems: 'Add',
    deleteList: 'Clear list',
    dialog: {
      addHeading: 'Create new domain list',
      editHeading: 'Update domain list',
      name: 'Name',
      deleteDLConfirmationTitle: 'Delete Domain list item',
      deleteDLConfirmationSubtitle: 'Are you sure you want to delete this item?',
      deleteAllDLConfirmationTitle: 'Delete ALL Domain list items?',
      deleteAllDLConfirmationSubtitle: 'Are you sure you want to delete ALL Domain list items!?',
      cancelButton: 'Cancel',
      submitButton: 'Submit',
      placeholder:
        'Please add urls (Separate urls by new line. Use (Shift + Enter) to go to new line )',
    },
    table: {
      name: 'Name',
      createdDate: 'Created Date',
      actionOptions: {
        delete: 'Delete Domain list item',
      },
    },
    errors: {
      domainListSaveFailed: 'Failed to save domain list. Please try later',
      domainListUpdateFailed: 'Failed to update domain list. Please try later',
      domainListItemDeleteFailed: 'Failed to delete domain list item. Please try later',
      domainListDeleteFailed: 'Failed to delete domain list. Please try later',
      domainListFetchFailed: 'Failed to load domain list. Please, try again later.',
    },
    validators: {
      required: 'Please enter urls separated by new line!',
    },
    sameDomainList: 'Domain List with the same urls exist: ',
  },
};
