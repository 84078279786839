import { IconProps } from 'feather-icons-react';

export const LibraryIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="47"
    height="44"
    viewBox="0 -4 47 44"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_d_1245_2646)">
      <g filter="url(#filter1_b_1245_2646)">
        <rect
          x="18.9272"
          y="4"
          width="7.5122"
          height="22.5366"
          rx="2"
          fill="url(#paint0_linear_1245_2646)"
          fillOpacity="0.8"
        />
      </g>
      <g filter="url(#filter2_b_1245_2646)">
        <rect
          x="27.1221"
          y="4"
          width="7.5122"
          height="22.5366"
          rx="2"
          fill="url(#paint1_linear_1245_2646)"
          fillOpacity="0.8"
        />
      </g>
      <rect
        x="33.9512"
        y="26.5366"
        width="5.46342"
        height="2.04878"
        rx="1.02439"
        transform="rotate(90 33.9512 26.5366)"
        fill="url(#paint2_linear_1245_2646)"
        fillOpacity="0.7"
      />
      <rect
        x="15.5122"
        y="26.5366"
        width="5.46342"
        height="2.04878"
        rx="1.02439"
        transform="rotate(90 15.5122 26.5366)"
        fill="url(#paint3_linear_1245_2646)"
        fillOpacity="0.7"
      />
      <g filter="url(#filter3_b_1245_2646)">
        <rect
          x="14.4575"
          y="6.78477"
          width="7.5122"
          height="19.1727"
          rx="2"
          transform="rotate(17.5293 14.4575 6.78477)"
          fill="url(#paint4_linear_1245_2646)"
          fillOpacity="0.7"
        />
      </g>
      <g filter="url(#filter4_b_1245_2646)">
        <rect
          x="8"
          y="26.5366"
          width="30.0488"
          height="2.04878"
          rx="1.02439"
          fill="white"
          fillOpacity="0.7"
        />
      </g>
    </g>
    <defs>
      <filter
        id="filter0_d_1245_2646"
        x="0"
        y="0"
        width="46.0488"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.5 0"
        />
        <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1245_2646" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_1245_2646"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_1245_2646"
        x="17.9272"
        y="3"
        width="9.51221"
        height="24.5366"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2646" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2646"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_1245_2646"
        x="26.1221"
        y="3"
        width="9.51221"
        height="24.5366"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2646" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2646"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_b_1245_2646"
        x="8.19189"
        y="6.29375"
        width="13.9199"
        height="21.5271"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2646" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2646"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_b_1245_2646"
        x="7"
        y="25.5366"
        width="32.0488"
        height="4.04878"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2646" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2646"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1245_2646"
        x1="26.2114"
        y1="2.63415"
        x2="3.44712"
        y2="21.3008"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1245_2646"
        x1="25.7562"
        y1="-1.46342"
        x2="40.7806"
        y2="34.5041"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1245_2646"
        x1="39.7561"
        y1="30.4207"
        x2="28.8292"
        y2="30.4207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1245_2646"
        x1="21.3171"
        y1="30.4207"
        x2="10.3903"
        y2="30.4207"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1245_2646"
        x1="14.799"
        y1="6.20378"
        x2="2.60735"
        y2="20.1737"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
    </defs>
  </svg>
);
