export const NEW_SEGMENT_ROOT = '/new-segment';
export const SEGMENT_ROOT = '/segment';

export const ADMIN_PANEL = '/admin-panel';

export const KEYWORD_GROUP = '/keyword-group';
export const CUSTOM_CATEGORY_ROOT = `${ADMIN_PANEL}/custom-category`;
export const NewSegmentPagesMap = {
  automated: 'automated',
  manual: 'manual',
  generalInfo: 'general-info',
  buckets: 'buckets',
};

export const NewPersonasPagesMap = {
  customCategory: 'custom-category',
  keywordGroups: 'keyword-groups',
};

export const SegmentPagesMap = {
  segment: 'segment',
  save: 'save',
  buckets: 'buckets',
  manual: 'manual',
};
export const CustomCategoryMap = {
  customCategory: 'custom-category',
  save: 'save',
};

export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',
  WELCOME: '/welcome',
  NEW_SEGMENT: NEW_SEGMENT_ROOT,
  NEW_SEGMENT_AUTOMATED: `${NEW_SEGMENT_ROOT}/${NewSegmentPagesMap.automated}`,
  NEW_SEGMENT_MANUAL: `${NEW_SEGMENT_ROOT}/${NewSegmentPagesMap.manual}`,
  NEW_SEGMENT_GENERAL_INFO: `${NEW_SEGMENT_ROOT}/${NewSegmentPagesMap.generalInfo}`,
  NEW_SEGMENT_BUCKETS: `${NEW_SEGMENT_ROOT}/${NewSegmentPagesMap.manual}/${NewSegmentPagesMap.buckets}`,
  SEGMENT_BY_ID: `${SEGMENT_ROOT}/:id`,
  SEGMENT_BY_ID_BUCKETS: `${SEGMENT_ROOT}/:id/${SegmentPagesMap.manual}/${SegmentPagesMap.buckets}`,
  SEGMENT_BY_ID_BUCKETS_RELATIVE_PATH: `${SegmentPagesMap.manual}/${SegmentPagesMap.buckets}`,
  SEGMENT_BY_ID_SAVE: `${SEGMENT_ROOT}/:id/${SegmentPagesMap.save}`,
  SEGMENT_BY_ID_MANUAL: `${SEGMENT_ROOT}/:id/${SegmentPagesMap.manual}`,
  UNIVERSITY: '/university',
  LIBRARY: '/library',
  MANAGEMENT: '/management',
  MEASUREMENT: '/measurement',
  CATEGORIES: '/categories',

  ADMIN_PANEL: ADMIN_PANEL,
  USERS: `${ADMIN_PANEL}/users`,
  USER: `${ADMIN_PANEL}/user/new-user`,
  USER_BY_ID: `${ADMIN_PANEL}/user/:id`,
  COMPANY: `${ADMIN_PANEL}/company`,
  KEYWORD_GROUP: `${ADMIN_PANEL}${KEYWORD_GROUP}`,
  NEW_KEYWORD_GROUP: `${ADMIN_PANEL}${KEYWORD_GROUP}/new-keyword-group`,
  KEYWORD_GROUP_BY_ID: `${ADMIN_PANEL}${KEYWORD_GROUP}/:id`,
  NEW_CUSTOM_CATEGORY: `${CUSTOM_CATEGORY_ROOT}/new-category`,
  CUSTOM_CATEGORY_BY_ID: `${CUSTOM_CATEGORY_ROOT}/:id`,
  CUSTOM_CATEGORY_LIST: `${ADMIN_PANEL}/custom-categories`,
  TEAM: `${ADMIN_PANEL}/team`,
  EXCLUSION_LIST: `${ADMIN_PANEL}/exclusion-list`,
  AUDIT: `${ADMIN_PANEL}/audit-log`,
  SEARCH_BY_VECTOR: `${ADMIN_PANEL}/search-by-vector`,
  SEGMENT_GROUP: `${ADMIN_PANEL}/segment-group`,
  DISTANCE_CHECKER: `${ADMIN_PANEL}/distance-checker`,
  DOMAIN_LIST: `${ADMIN_PANEL}/domain-list`,
  CONTEXTA: `${ADMIN_PANEL}/contexta-analisys-by-publisher`,
} as const;

type Keys = keyof typeof ROUTES;
export type Route = (typeof ROUTES)[Keys];
