import { PayloadAction } from '@reduxjs/toolkit';
import i18next from 'i18next';
import { all, call, put, select, takeEvery, takeLatest } from 'redux-saga/effects';

import { notifyError } from 'services/logService';

import { changeStatusSaga } from './sagas/changeStatusSaga';
import { copySegmentSaga } from './sagas/copySegmentSaga';
import { segmentBriefUploadSaga } from './sagas/segmentBriefUploadSaga';
import { segmentDeleteSaga } from './sagas/segmentDeleteSaga';
import { segmentSaveSaga } from './sagas/segmentSaveSaga';
import { segmentUpdateSaga } from './sagas/segmentUpdateSaga';
import { segmentsApi } from './segmentApi';
import { SegmentDetailsResponse } from './segmentApiTypes';
import { getSegmentBucketsLength } from './segmentSelectors';
import {
  changeStatus,
  copySegment,
  deleteSegment,
  fetchSegmentDetails,
  fetchSegmentDetailsFailure,
  fetchSegmentDetailsSuccess,
  getBriefUploadData,
  initSegmentDetails,
  saveSegment,
  setBuckets,
  setSegmentInfo,
  setSensitiveSubjects,
  updateSegment,
  updateValues,
} from './segmentSlice';
import { extractBuckets } from './transformUtils';
import { ManualStepValues } from './types';

function* getSegmentDetailsSaga({
  payload: { id, navPath },
}: PayloadAction<{ id: string; navPath: string }>) {
  try {
    const segmentDetails: SegmentDetailsResponse = yield call(segmentsApi.getSegmentById, id);
    const buckets: ManualStepValues[] = yield call(extractBuckets, segmentDetails);
    let debugStatus = '';

    if (segmentDetails.debugUntil !== null) {
      const debugDate = new Date(segmentDetails.debugUntil);
      const today = new Date();

      // Set the date to the end of the day
      debugDate.setHours(23, 59, 59, 999);
      debugStatus = debugDate >= today ? 'Active' : 'Inactive';
    }
    // We need to perform the deduplication of the sensitiveSubjects cause sometimes the backend return them duplicated
    yield put(setSensitiveSubjects([...new Set(segmentDetails.sensitiveSubjects)]));
    yield put(setBuckets(buckets));
    yield put(
      updateValues({
        name: segmentDetails.name,
        ssp: segmentDetails.ssp,
        async: segmentDetails.async,
        realtime: segmentDetails.realtime,
        debugUntilStatus: debugStatus,
        domainInclusionListId: segmentDetails.domainInclusionListId
          ? segmentDetails.domainInclusionListId
          : null,
        domainExclusionListId: segmentDetails.domainExclusionListId
          ? segmentDetails.domainInclusionListId
          : null,
        isIncluded:
          !segmentDetails.domainInclusionListId && !segmentDetails.domainExclusionListId
            ? true
            : !!segmentDetails.domainInclusionListId,
      }),
    );

    yield put(setSegmentInfo(segmentDetails));

    yield put(fetchSegmentDetailsSuccess({ navPath }));
  } catch (error) {
    yield put(
      fetchSegmentDetailsFailure({
        message: i18next.t('errors.segmentDetailsFetchFailed'),
      }),
    );
    notifyError(error);
  }
}

function* segmentDetailsInitSaga({ payload }: PayloadAction<{ id: string }>) {
  const bucketsLength: number = yield select(getSegmentBucketsLength);

  if (bucketsLength > 0) {
    return;
  } else {
    yield put(fetchSegmentDetails(payload));
  }
}

export function* segmentSagas() {
  yield all([
    takeLatest(saveSegment.type, segmentSaveSaga),
    takeLatest(updateSegment.type, segmentUpdateSaga),
    takeLatest(deleteSegment.type, segmentDeleteSaga),
    takeLatest(copySegment.type, copySegmentSaga),
    takeLatest(changeStatus.type, changeStatusSaga),
    takeLatest(getBriefUploadData.type, segmentBriefUploadSaga),
    takeEvery(fetchSegmentDetails.type, getSegmentDetailsSaga),
    takeEvery(initSegmentDetails.type, segmentDetailsInitSaga),
  ]);
}
