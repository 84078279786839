import { IconProps } from 'feather-icons-react';

export const HomeIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="44"
    height="44"
    viewBox="-4 -4 44 44"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_b_1245_2620)">
      <circle cx="11.5" cy="10.5" r="6.5" fill="url(#paint0_linear_1245_2620)" fillOpacity="0.7" />
    </g>
    <g filter="url(#filter1_bd_1245_2620)">
      <path
        d="M26.9367 32H8.70167C6.1 32 4 29.8883 4 27.2983V16.11C4 14.6983 4.64167 13.3567 5.73833 12.4583L14.85 5.05C16.5767 3.65 19.05 3.65 20.7767 5.05L29.8883 12.4467C30.985 13.345 31.6267 14.6867 31.6267 16.0983V27.2867C31.6267 29.8883 29.515 31.9883 26.925 31.9883L26.9367 32Z"
        fill="url(#paint1_linear_1245_2620)"
        fillOpacity="0.7"
        shapeRendering="crispEdges"
      />
    </g>
    <g filter="url(#filter2_bd_1245_2620)">
      <rect
        x="11"
        y="23"
        width="14"
        height="3"
        rx="1.5"
        fill="url(#paint2_linear_1245_2620)"
        fillOpacity="0.7"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1245_2620"
        x="4"
        y="3"
        width="15"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2620" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2620"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_bd_1245_2620"
        x="0"
        y="0"
        width="43.6267"
        height="44"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2620" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dx="4" dy="4" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.16 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_1245_2620"
          result="effect2_dropShadow_1245_2620"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1245_2620"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_bd_1245_2620"
        x="5"
        y="21"
        width="26"
        height="15"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2620" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_1245_2620"
          result="effect2_dropShadow_1245_2620"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1245_2620"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1245_2620"
        x1="5.59091"
        y1="3.60606"
        x2="0.482421"
        y2="18.5457"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1245_2620"
        x1="1.87487"
        y1="4"
        x2="28.8427"
        y2="34.3334"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1245_2620"
        x1="16.5"
        y1="22"
        x2="9.85458"
        y2="17.8679"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
    </defs>
  </svg>
);
