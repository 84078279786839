import { IconProps } from 'feather-icons-react';

export const UserProfileIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="31"
    viewBox="-1 3 29 31"
    fill="none"
    className={className}
  >
    <path
      d="M3 27C3 20.9249 7.92487 16 14 16C20.0751 16 25 20.9249 25 27V29.6364C25 30.3895 24.3895 31 23.6364 31H4.36364C3.61052 31 3 30.3895 3 29.6364V27Z"
      fill="url(#paint0_linear_1245_2659)"
      fillOpacity="0.7"
    />
    <g filter="url(#filter0_bd_1245_2659)">
      <circle
        cx="14.091"
        cy="12.091"
        r="6.09099"
        fill="url(#paint1_linear_1245_2659)"
        fillOpacity="0.6"
      />
    </g>
    <defs>
      <filter
        id="filter0_bd_1245_2659"
        x="0"
        y="0"
        width="28.1819"
        height="28.182"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2659" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="2" />
        <feGaussianBlur stdDeviation="4" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.38 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_1245_2659"
          result="effect2_dropShadow_1245_2659"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1245_2659"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1245_2659"
        x1="14"
        y1="29"
        x2="21.5"
        y2="8.5"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1245_2659"
        x1="8"
        y1="4.5"
        x2="3.46888"
        y2="15.4792"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
    </defs>
  </svg>
);
