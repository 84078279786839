import { all } from 'redux-saga/effects';

import { adminPanelSaga } from 'modules/adminPanel/adminPanelSaga';
import { authFlowSagas } from 'modules/auth/authFlowSagas';
import { chartsSagas } from 'modules/charts/chartsSagas';
import { labelsSagas } from 'modules/labels/labelsSagas';
import { nanoBotSagas } from 'modules/nanoBot/nanoBotSagas';
import { notificationSagas } from 'modules/notifications';
import { emptySegmentSagas } from 'modules/segment/emptySegment/emptySegmentSagas';
import { segmentSagas } from 'modules/segment/segmentSagas';

import { expiredDateSagas } from '../modules/segment/sagas/expiredDateSagas';

export function* rootSaga() {
  yield all([
    authFlowSagas(),
    nanoBotSagas(),
    labelsSagas(),
    expiredDateSagas(),
    segmentSagas(),
    chartsSagas(),
    notificationSagas(),
    emptySegmentSagas(),
    adminPanelSaga(),
  ]);
}
