import { IconProps } from 'feather-icons-react';

export const MeasurementIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="38"
    viewBox="0 -4 46 38"
    fill="none"
    className={className}
  >
    <g filter="url(#filter0_b_1245_2653)">
      <path
        d="M9 12C9 10.8954 9.89543 10 11 10H18C19.1046 10 20 10.8954 20 12V26H9V12Z"
        fill="url(#paint0_linear_1245_2653)"
        fillOpacity="0.4"
      />
    </g>
    <g filter="url(#filter1_b_1245_2653)">
      <path
        d="M23 7C23 5.89543 23.8954 5 25 5H32C33.1046 5 34 5.89543 34 7V26H23V7Z"
        fill="url(#paint1_linear_1245_2653)"
        fillOpacity="0.6"
      />
    </g>
    <g filter="url(#filter2_b_1245_2653)">
      <path
        d="M16 2C16 0.89543 16.8954 0 18 0H25C26.1046 0 27 0.895431 27 2V26H16V2Z"
        fill="url(#paint2_linear_1245_2653)"
        fillOpacity="0.8"
      />
    </g>
    <g filter="url(#filter3_bd_1245_2653)">
      <path
        d="M37.2432 11.9189L27.0521 21.8315C25.6254 23.2193 23.3532 23.2193 21.9264 21.8315L19.8099 19.7728C18.3966 18.3981 16.1503 18.3834 14.719 19.7394L7 27M32.7297 11H37.2432C37.7507 11 38.1622 11.4114 38.1622 11.9189V16.4324"
        stroke="url(#paint3_linear_1245_2653)"
        strokeOpacity="0.6"
        strokeWidth="2"
        strokeLinecap="round"
        shapeRendering="crispEdges"
      />
    </g>
    <defs>
      <filter
        id="filter0_b_1245_2653"
        x="8"
        y="9"
        width="13"
        height="18"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2653" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2653"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_b_1245_2653"
        x="22"
        y="4"
        width="13"
        height="23"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2653" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2653"
          result="shape"
        />
      </filter>
      <filter
        id="filter2_b_1245_2653"
        x="15"
        y="-1"
        width="13"
        height="28"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2653" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_backgroundBlur_1245_2653"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_bd_1245_2653"
        x="0"
        y="8"
        width="45.1621"
        height="30"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="0.5" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1245_2653" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="4" />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.4 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_1245_2653"
          result="effect2_dropShadow_1245_2653"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1245_2653"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1245_2653"
        x1="6.5"
        y1="1"
        x2="8.07393"
        y2="29.3831"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1245_2653"
        x1="38.5"
        y1="-4.5"
        x2="15.7099"
        y2="17.4208"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_1245_2653"
        x1="14"
        y1="-6.30303"
        x2="28.472"
        y2="37.6695"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_1245_2653"
        x1="7.46192"
        y1="10.4848"
        x2="3.64278"
        y2="31.6153"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
    </defs>
  </svg>
);
