import { IconProps } from 'feather-icons-react';

export const AddSegmentIcon = ({ className }: IconProps) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="38"
    height="38"
    viewBox="-4 -4 38 38"
    fill="none"
    className={className}
  >
    <rect width="27" height="27" rx="6" fill="url(#paint0_linear_1546_337)" fillOpacity="0.7" />
    <rect
      x="5"
      y="5"
      width="17"
      height="17"
      rx="4"
      fill="url(#paint1_linear_1546_337)"
      fillOpacity="0.2"
      stroke="white"
      strokeOpacity="0.4"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeDasharray="2 3"
    />
    <g filter="url(#filter0_bd_1546_337)">
      <circle cx="22" cy="22" r="8" fill="white" fillOpacity="0.4" shapeRendering="crispEdges" />
    </g>
    <path d="M21.9998 18.5714V25.4286" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <path d="M25.4285 22L18.5713 22" stroke="white" strokeWidth="2" strokeLinecap="round" />
    <defs>
      <filter
        id="filter0_bd_1546_337"
        x="8"
        y="8"
        width="28"
        height="28"
        filterUnits="userSpaceOnUse"
        colorInterpolationFilters="sRGB"
      >
        <feFlood floodOpacity="0" result="BackgroundImageFix" />
        <feGaussianBlur in="BackgroundImageFix" stdDeviation="1" />
        <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1546_337" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="3" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.129412 0 0 0 0 0.00392157 0 0 0 0 0.196078 0 0 0 0.2 0"
        />
        <feBlend
          mode="normal"
          in2="effect1_backgroundBlur_1546_337"
          result="effect2_dropShadow_1546_337"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect2_dropShadow_1546_337"
          result="shape"
        />
      </filter>
      <linearGradient
        id="paint0_linear_1546_337"
        x1="-2.07692"
        y1="2.55028e-07"
        x2="23.8846"
        y2="29.5962"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#00FFFA" />
        <stop offset="1" stopColor="#8C48BF" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_1546_337"
        x1="5.77273"
        y1="4.48485"
        x2="-0.907603"
        y2="24.0213"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="#00FFFA" />
      </linearGradient>
    </defs>
  </svg>
);
